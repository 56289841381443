import { template as template_76b203aeeadd496689e6b7d6cc22a1db } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_76b203aeeadd496689e6b7d6cc22a1db(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
