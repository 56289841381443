import { template as template_afb60982134942b9a2308b6ba4412734 } from "@ember/template-compiler";
const FKText = template_afb60982134942b9a2308b6ba4412734(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
