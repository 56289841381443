import { template as template_70621f49b3a14435b7d13610855a8346 } from "@ember/template-compiler";
const SidebarSectionMessage = template_70621f49b3a14435b7d13610855a8346(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
