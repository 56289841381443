import { template as template_27f4f65feb9e4b069de30efd44d5c0d3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_27f4f65feb9e4b069de30efd44d5c0d3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
